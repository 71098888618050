/* HERO */

.hero-gradient-1 {
    background: radial-gradient(50% 196.06% at 50% 100%, rgba(204, 0, 255, 0.24) 0%, rgba(0, 0, 0, 0) 100%);
}
.bg-radial-gradient {
  background: radial-gradient(66.6% 133.19% at 50% 100%, rgba(36, 36, 36, 0.58) 36.6%, #000 100%);
}
.background-middle {
    background-color:#080808;
}

.background-columns {
    background-color: #0F0F0F;
}
.background-button{
    background: linear-gradient(90.72deg, rgba(144, 0, 194, 0.65) 0%, #9000C2 43.68%, rgba(139, 1, 187, 0.54) 99.94%);
    box-shadow: 0px 1px 54px rgba(0, 0, 0, 0.8);
}
.background-reference {
    background: linear-gradient(357.34deg, #64255F -99.84%, #010101 100.72%);
    transform: matrix(1, 0, 0, 1, 0, 0);
}
.border-frame-white {
    border: 1px solid rgba(249, 233, 255, 0.5);
    box-shadow: 0px 0px 24px #000000, inset 0px 0px 5px rgba(249, 233, 255, 0.55);
}
.border-frame-pink {
    background: rgba(32, 32, 32, 0.1);
    box-shadow: 1px 1px 6px rgba(153, 153, 153, 0.6), inset -1px -1px 5px rgba(153, 153, 153, 0.35);
}
.hide {
    visibility: hidden;
}
.show:hover .hide {
    visibility: visible;
}
.blur-box {
    background: linear-gradient(0.49deg, #25142E 0.33%, rgba(41, 15, 50, 0) 99.5%);
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px);
    border-radius: 0px 0px 22px 22px;
}
.custom-border-gradient {
  background: linear-gradient(to bottom, #db00ff, #3d0047); /* Adjust colors as needed */
  background-clip: border-box;
}


/* FONTS */

h1, h2, h3, a {
    font-family: "Montserrat", serif;
}
p {
    font-family: "Varela Round", serif;
}

/* ANIMATION-LOGOS */

.logo-container {
    display: flex;
    flex-wrap: nowrap;
    animation: logo-animation 30s linear infinite;
    gap: 48px;
    padding-bottom: 6px;
    height: 100%;
}
.logo-container img {
    height: auto;
    max-width: 100%;
    display: block;
}
.logos-blur-mask {
    overflow: hidden;
    opacity: 1;
}

@keyframes logo-animation {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(0%);
    }
    }


/* FAQ */

.faq-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.tranzi {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    transition-property: background-color, background-image, border-color, color, fill, stroke, opacity, box-shadow, transform;
}
/* CONTACT FORM PHONE FIELD */
.no-arrow {
    -moz-appearance: textfield;
}
.no-arrow::-webkit-inner-spin-button {
    display: none;
}
.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
  /* MODAL */
.modal-open {
    overflow: hidden;
}
.modal-open {
    .no-header{
        display: none;
    }
}
.splide__arrow {
    background: linear-gradient(#281D34,#8F16AD)!important;
    opacity: 100;
}
.splide__arrow:hover {
    background: linear-gradient(#281D34,#8F16AD)!important;
}
.splide__arrow svg {
    fill: #f9f9f9!important;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
